import React from 'react';
import Mailer from './Mailer';

const Bookkeeping = () => {
  return (
    <>
      <div className="container rupek-top-pic">
        <img src="images/bookkeeping1.jpg" className="d-block w-100" alt="taxation-services"/>
      </div>
      <div className="container">
      <div className="row">
        <div className="col-7">
          <div className="jumbotron jumbotron-fluid m-0 pt-2 pb-3">
            <div className="container">
              <h3 className="display-5 text-center">Bookkeeping</h3>
              <p className="text-sm-left">Bookkeeping is tedious even with a full time accountant that is why it is always advisable to outsource the services. 
              At Rupek offer professional bookkeeping.</p>
              <p className="text-sm-left">Did you know that outsourcing bookkeeping is cheaper than hiring a full time accountant? If you are a small 
              to medium enterprise you will find out that you cannot be in sales and marketing as well as in bookkeeping. Bookkeeping will eat out your 
              time and leave only a little time to do sales and marketing, resulting in low sales. You need more time to do what matters most in business, 
              sales and marketing. More sales more profits, less sales less profits or losses. That is the reason you need to outsource bookkeeping. </p>
              <p className="text-sm-left">Bookkeeping being so involving and time consuming, we at Rupek we have experts who are good at it. We always endeavor 
              to understand our clients&apos; needs in order to satisfy them in the best way we always do. <span className="rupek-red-standard font-weight-bolder">
              We provide guarantee of confidentiality and compliance on agreed upon terms such as deadlines.</span> </p>
              <p className="text-sm-left">The following are the bookkeeping services that you can outsource from Rupek: </p>
              <ol>
                <li>Invoicing</li>
                <li>Management accounting i.e. account receivable &amp; accounts payable</li>
                <li>Bank reconciliation</li>
                <li>Trial balance and P&amp;L accounts</li>
                <li>General ledger</li>
              </ol>
              </div>
          </div>
        </div>
        <div className="col-5">
          <Mailer />
        </div>
        </div>
      </div>
    </>
  )
}

export default Bookkeeping
import './App.css';
import Header from './components/Header';
import Home from './components/Home';
import Taxation from './components/Taxation';
import Bookkeeping from './components/Bookkeeping';
import Auditing from './components/Auditing';
import Consultancy from './components/Consultancy';
import ContactUs from './components/ContantUs';
import Missing from './components/Missing';
import Footer from './components/Footer';
import {Route, Routes} from 'react-router-dom';
// import {useState, useEffect} from 'react';

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />}  />
        <Route exact path="/taxation" element={<Taxation />}  />
        <Route exact path="/bookkeeping" element ={<Bookkeeping />}   />
        <Route path="/auditing" element ={<Auditing/>}  exact />
        <Route path="/consultancy" element ={<Consultancy/>}  exact />
        <Route path="/contact-us" element ={<ContactUs/>}  exact />
        <Route path="/*" element={<Missing/>}  exact />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;

import React from 'react'
import Carousel from './Carousel'
const Home = () => {
  return (
    <main>    
    <Carousel />
    <div className="container">
      <h3 className="text-center rupek-red-standard pt-2 pb-2">Services</h3>
      <div className="card-deck">
        <div className="card">
        <a href="/taxation"><img src="images/taxation1.jpg" className="card-img-top rounded-circle" alt="taxation services"/></a>
        <div className="card-body">
          <h5 className="card-title text-center">Taxation</h5>
          <p className="card-text">We do calculate and return monthly and annual taxes payable by our clients and advise on tax submission dates.</p>
        </div>
        </div>
        <div className="card">
        <a href="/bookkeeping"><img src="images/bookkeeping.jpg" className="card-img-top rounded-circle border border-dark" alt="bookkeeping"/></a>
        <div className="card-body">
          <h5 className="card-title text-center">Bookkeeping</h5>
          <p className="card-text">Bookkeeping is tedious even with a full time accountant that is why it is always advisable to outsource the services. Rupek offers professional bookkeeping.</p>
        </div>
        </div>
        <div className="card">
        <a href="/auditing"><img src="images/audit-services.jpg" className="card-img-top rounded-circle" alt="auditing services"/></a>
        <div className="card-body">
          <h5 className="card-title text-center">Auditing</h5>
          <p className="card-text">As an auditing firm, we conform to professional standards, maintain independence and objectivity, and keep client confidentiality. </p>
        </div>
        </div>
        <div className="card">
        <a href="/consultancy"><img src="images/consultancy.jpg" className="card-img-top rounded-circle" alt="consultancy services"/></a>
        <div className="card-body">
          <h5 className="card-title text-center">Consultancy</h5>
          <p className="card-text">Investors and entrepreneurs need financial advisory that considers critical factors such as changing regulations, technological dynamics and market complexities.</p>
        </div>
        </div>
      </div>
    </div>
    </main>
  )
}

export default Home
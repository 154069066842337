import React from 'react'
import Mailer from './Mailer'

const Taxation = () => {
  return (
    <main>
      <div className="container rupek-top-pic">
		    <img src="images/taxation1.jpg" className="d-block w-100" alt="taxation-services"/>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-7">
            <div className="jumbotron jumbotron-fluid m-0 pt-2 pb-3">
              <div className="container">
                <h3 className="display-5 text-center">Taxation services</h3>
                <p className="text-sm-left">Every government needs revenue to run its functions and development projects. 
                In Kenya the main source of revenue is collection of taxes. Revenue collection in Kenya has increased significantly in the past two decades due to 
                tax laws. The tax laws determine taxation practices, tax performance and the collected revenue. The taxation law is governed by a set policies 
                that change from time to time forming the basis for training in order to stay updated. Since training is time consuming and the concepts 
                complex <span className="font-weight-bold">at Rupek Consultants we offer taxation services that make our individual and corporate clients avoid 
                penalties by paying taxes correctly and on time.</span></p>
                <p>Taxes in Kenya are divided into direct and indirect taxes.  Direct taxes are paid by individuals while indirect taxes are paid by third 
                parties. Examples of direct taxes are income and commission taxes. Income taxes are paid by employers. The cost of direct taxes are borne by 
                individuals. Indirect taxes are not paid directly by taxed individuals but rather transferred to third parties such as sellers and service 
                providers. Example of indirect tax is value added tax (VAT). Taxation calculation is intricate for laymen and therefore requires professionals 
                who understand the elaborate tax system in Kenya. Get these professional services today from Rupek Consultants.</p>
                <p className="rupek-red-standard font-weight-bolder">We offer timely, professional and accurate taxation services.</p>
                </div>
            </div>
          </div>
          <div className="col-5">
            <Mailer />
          </div>
        </div>
      </div>
    </main>
  )
}

export default Taxation

import React from 'react'

const Missing = () => {
  return (
    <main>
    <h1 className='PageNotFound'>
    Page not found!!!!    
    </h1>
      
    </main>
  )
}

export default Missing

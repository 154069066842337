import React, {useState} from 'react';
// import emailjs from 'emailjs-com';

import axios from 'axios';
import { useEffect } from 'react';
const Mailer = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(()=> {
    setTimeout(() => {if (success){
      setName('');
        setEmail('');
        setSubject('');
        setMessage('');
        setSuccess(false) }
      }, 3000)
  }, [success])

  const handleSend= async (e) => {
    e.preventDefault();
    // const data = {name, email, subject, message}
    // const options = {headers: {"content-type": "text/html"}}
    try {
      // let res = await axios.post("http://localhost:5000/send_mail", {name, email, subject, message});
      // let data = res.data
      if (await axios.post("https://rupekapi.rupekconsultants.co.ke/send_mail", {name, email, subject, message})){
        setSuccess(true)
        // console.log(success)
      }
      
            
        // emailjs.sendForm(
        //   'service_qn617sq',
        //   'template_m402r0p',
        //   e.target,
        //   'OFffzFYoo1KDHW8Y9'
        //   )
        //   .then(res => {
        //     // e.target.value = '';
        //     console.log(res);
        //   }).catch(err => {
        //     console.log(err);
        //   }) 
      
        
    }
    catch (e) {
      setSuccess(false)
    }
  };
    
  return (
    <div className="Mailer jumbotron jumbotron-fluid m-0 pt-1 pb-4 rupek-bg-red-light">
      <div className="container">
      {success && <div className='success'>Message has been sent successfully!</div>}
        <form onSubmit={handleSend}>
          <h3 className="display-5 text-center">Talk to us</h3>
          <div className="form-group">
          <label htmlFor="rupekEnquiryForm">Your Name</label>
          <input 
            type="text" 
            className="form-control" 
            id="rupekEnquiryForm" 
            name = "name" 
            value = {name}
            placeholder="Enter your name"
            onChange={(e) => setName(e.target.value)} 
            required/>
          </div>
          <div className="form-group">
          <i className="fas fa-envelope prefix"></i>
          <label htmlFor="rupekEnquiryForm2">Your Email</label>
          <input 
            type="text" 
            className="form-control" 
            id="rupekEnquiryForm2" 
            name = "email" 
            value ={email}
            placeholder="Enter your email" 
            onChange={(e) => setEmail(e.target.value)} 
            required/>
          </div>
          <div className="form-group">
          <label htmlFor="rupekEnquiryForm2">Subject</label>
          <input 
            type="text" 
            className="form-control" 
            id="rupekEnquiryForm2" 
            name = "subject" 
            value={subject}
            placeholder="Subject"
            onChange={(e) => setSubject(e.target.value)} 
            required/>
          </div>
          <div className="form-group">
          <label htmlFor="rupekEnquiryForm">Message</label>
          <textarea 
            className="form-control" 
            id="rupekEnquiryForm" 
            rows="3" 
            name = "message" 
            value={message}
            placeholder="Message"
            onChange={(e) => setMessage(e.target.value)} 
            ></textarea>
          </div>
          <button className="btn rupek-bg-blue-light w-100" type="submit">Send email</button>
        </form>	
      </div>
    </div>
  )
}

export default Mailer
import React from 'react'

const Carousel = () => {
  return (
    <div className="container">
		<div id="carouselExampleCaptions" className="carousel slide" data-ride="carousel">
		  <ol className="carousel-indicators">
			<li data-target="#carouselExampleCaptions" data-slide-to="0" className="active"></li>
			<li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
			<li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
		  </ol>
		  <div className="carousel-inner">
			<div className="carousel-item active">
			  <img src="images/bookkeeping-services.jpg" className="d-block w-100" alt="bookkeeping"/>
			  <div className="carousel-caption d-none d-md-block">
				<h5 className="text-primary">You focus on your business</h5>
				<p className="text-primary">Let us worry about your books</p>
			  </div>
			</div>
			<div className="carousel-item">
			  <img src="images/taxation.jpg" className="d-block w-100" alt="taxation-services"/>
			  <div className="carousel-caption d-none d-md-block">
				<h5>Taxation Services</h5>
				<p>Timely, Professionally, Accurately</p>
			  </div>
			</div>
			<div className="carousel-item">
			  <img src="images/audit.jpg" className="d-block w-100" alt="auditing"/>
			  <div className="carousel-caption d-none d-md-block">
				<h5 className="text-primary">Auditing Services</h5>
				<p className="text-primary">Professionalism, Independency, Objectivity and Confidentiality	</p>
			  </div>
			</div>
		  </div>
		  <a className="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
			<span className="carousel-control-prev-icon" aria-hidden="true"></span>
			<span className="sr-only">Previous</span>
		  </a>
		  <a className="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
			<span className="carousel-control-next-icon" aria-hidden="true"></span>
			<span className="sr-only">Next</span>
		  </a>
		</div>
	</div>
  )
}

export default Carousel
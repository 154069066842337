import React from 'react'

const Nav = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light rupek-bg-light">
		  <a className="navbar-brand" href="/"><img src="images/rupek-logo.png" width="100" height="50" alt="rupek-logo" /></a>
		  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
			<span className="navbar-toggler-icon"></span>
		  </button>
		  <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
			<div className="navbar-nav text-right">
			  <a className="nav-item nav-link" href="/">Home</a>
			  <a className="nav-item nav-link" href="/taxation">Taxation</a>
			  <a className="nav-item nav-link" href="/bookkeeping">Bookkeeping</a>
			  <a className="nav-item nav-link" href="/auditing">Auditing</a>
			  <a className="nav-item nav-link" href="/consultancy">Consultancy</a>
			  <a className="nav-item nav-link" href="/contact-us">Contact Us</a>
			</div>
		  </div>
		</nav>
  )
}

export default Nav

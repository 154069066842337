import React from 'react';
import Mailer from './Mailer';

const Auditing = () => {
  return (
    <>
      <div className="container rupek-top-pic">
        <img src="images/auditing-service.jpg" className="d-block w-100" alt="taxation-services"/>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-7">
            <div className="jumbotron jumbotron-fluid m-0 pt-2 pb-3">
              <div className="container">
                <h3 className="display-5 text-center">Auditing</h3>
                <p className="text-sm-left">As an auditing firm, we conform to professional standards, maintain independence and objectivity, and keep client 
                <span>confidentiality</span></p>
                <p>When choosing an auditor you need to consider a firm that conforms to professional standards, maintains independence, is objective and 
                keeps clients confidentiality. These are the values at Rupek. We provide audit services to small and medium businesses.</p>
                <p className="rupek-red-standard font-weight-bolder"></p>
                </div>
            </div>
          </div>
          <div className="col-5">
            <Mailer />
          </div>
        </div>
      </div>
    </>
  )
}

export default Auditing
import React from 'react'

const Footer = () => {
  var today = new Date()
  return (
    <footer className="page-footer font-small rupek-bg-black-dark text-light">
     
      <div className="container text-center text-md-left mt-2">

      
      <div className="row mt-3">

        
        <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-1">

       
        <h6 className="text-uppercase font-weight-bold pt-3">Rupek Consultants</h6>
        <hr className="bg-danger accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{width: "60px"}}/>
        <p>Confidential, timely and independent for you</p>

        </div>
        <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">

        <h6 className="text-uppercase font-weight-bold pt-3">Services</h6>
        <hr className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{width: "60px"}}/>
        <p>
          <a href="/taxation">Taxation</a>
        </p>
        <p>
          <a href="/bookkeeping">Bookkeeping</a>
        </p>
        <p>
          <a href="/auditing">Auditing</a>
        </p>
        <p>
          <a href="/consultancy">Consultancy</a>
        </p>
        <p>
          <a href="/contact-us">Contact US</a>
        </p>

        </div>
       
        
        <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">

        
        <h6 className="text-uppercase font-weight-bold pt-3">Useful links</h6>
        <hr className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{width: "60px"}}/>
        <p>
          <a href="https://www.kra.go.ke/en/" target="_blank" rel="noopener noreferrer">KRA</a>
        </p>
        <p>
          <a href="http://www.nhif.or.ke/healthinsurance/" target="_blank" rel="noopener noreferrer">NHIF</a>
        </p>
        <p>
          <a href="https://www.nssf.or.ke/" target="_blank" rel="noopener noreferrer">NSSF</a>
        </p>

        </div>
        
        
        <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">

        <h6 className="text-uppercase font-weight-bold pt-3">Contact</h6>
        <hr className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{width: "60px"}}/>
        <p>
          <i className="fas fa-home mr-0"></i> PRINTERS HOUSE</p>
        <p>
          <i className="fas fa-envelope mr-0"></i> <a href="mailto:info@rupekconsultants.co.ke" target="_blank" rel="noopener noreferrer">info@rupekconsultants.co.ke</a></p>
        <p>
          <i className="fas fa-phone mr-0"></i> + 254 700 195 586</p>
        <p>
          <i className="fas fa-website mr-0"></i>www.rupekconsultants.co.ke</p>

        </div>
        

      </div>
      

      </div>
      
      
      <div className="footer-copyright text-center py-2">&copy;{today.getFullYear()}: Rupek Consultants
      </div>
     
      

    </footer>
  )
}

export default Footer
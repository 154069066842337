import React from 'react'
import Mailer from './Mailer'

const ContactUs = () => {
  return (
    <main>
      <div className="container rupek-top-pic">
        <img src="images/call-us-now.jpg" className="d-block w-100" alt="taxation-services"/>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="jumbotron jumbotron-fluid m-0 pt-2 pb-3">
              <div className="container">
                <iframe src="https://maps.google.com/maps?q=inkpaste&t=&z=13&ie=UTF8&iwloc=&output=embed" width="100%" height="320" frameBorder="0"  allowFullScreen title='Map' style={{border: "0"}}></iframe>
                <a href="https://www.embedgooglemap.net">map</a>
                </div><style>.mapouter(position:relative;text-align:right;height:500px;width:600px;).gmap_canvas(overflow:hidden;background:none!important;height:500px;width:600px;)</style></div>
              </div>
            </div>	
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <Mailer />
              </div>
            </div>
          </div>	
    </main>
  )
}

export default ContactUs
import React from 'react'
import Mailer from './Mailer'

const Consultancy = () => {
  return (
    <main>
      <div className="container rupek-top-pic">
        <img src="images/consulting.jpg" className="d-block w-100" alt="taxation-services"/>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-7">
            <div className="jumbotron jumbotron-fluid m-0 pt-2 pb-3">
              <div className="container">
                <h3 className="display-5 text-center">Consultancy</h3>
                <p className="text-sm-left">Business investors and entrepreneurs need financial advisory in order to make informed business decisions. 
                Financial advisory takes care of critical factors such as changing regulations, technological dynamics and market complexities. A business 
                cannot make profit and sometimes make it exponentially unless it is in cognizant of opportunities to take advantage of and threats that face 
                it. At Rupek we do</p>
                
                <ol>
                  <li>Competition analysis</li>
                  <li>Market assessment using SWOT and PEST analysis</li>
                  <li>Tax compliance</li>
                  <li>Risk management</li>
                </ol>
                </div>
            </div>
          </div>
          <div className="Mailer  col-5">
            <Mailer />
          </div>
        </div>
      </div>
    </main>
  )
}

export default Consultancy
import React from 'react'
import Contactline from './Contactline';
import Nav from './Nav';

const Header = () => {
  return (
    <header className='container'>
        <Contactline />
        <Nav/>      
    </header>
  )
}

export default Header
